import { SecurityService } from '../services/security.service';
import { isEntityEnabled, toPascalCase, SecurityProfile } from '@voice/utils';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { isPlural, plural } from 'pluralize';
import { NotificationsService } from '../services/notifications.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard implements CanActivate {
  constructor(
    private readonly securityService: SecurityService,
    private readonly notificationsService: NotificationsService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const url = toPascalCase(route.url[0].path.replace('/', ''));
      const entity = isPlural(url) ? url : plural(url);
      this.securityService
        .getSecurityProfile()
        .then((securityProfile: SecurityProfile) => {
          if (!isEntityEnabled(securityProfile, entity)) {
            this.notificationsService
              .error(
                "Access denied. You don't have permission to perform this action."
              )
              .then(() => resolve(false));
          } else {
            resolve(true);
          }
        });
    });
  }
}
