import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BrowserDetectorService {
  getBrowserAgent(): string {
    return window.navigator.userAgent;
  }

  isMobile(): boolean {
    return window.navigator.maxTouchPoints > 0;
  }
}
