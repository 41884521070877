<div class="container">
  <div class="header">
    <div class="flex flex-row align-items-center flex-wrap" style="min-height:100%; min-width:100%;">
      <div id="headerMenuHandler" class="flex align-items-center justify-content-center text-white">
        <i
          [ngClass]="{'pi':true, 'pi-bars':menuExpanded, 'pi-ellipsis-v':!menuExpanded}"
          style="font-size: 2rem; cursor:pointer;"
          (click)="toggleMenu()"
          *ngIf="!isMobile"></i>
        <i
          class="pi pi-bars"
          style="font-size: 2rem; cursor:pointer;"
          (click)="mainMenu.toggle($event)"
          *ngIf="isMobile"></i>
      </div>
      <div id="headerTitle" class="flex align-items-center justify-content-start text-white">
        <img src="/udt-logo-white.svg" height="40" />
        <span class="ml-5" *ngIf="!isMobile">Voice Provision Web Console</span>
      </div>
      <div id="headerSearch" class="flex align-items-center justify-content-end pr-2">
        <p-iconField iconPosition="right">
          <p-inputIcon styleClass="pi pi-search" />
          <input id="searchInput" name="searchInput" type="text" pInputText />
        </p-iconField>
      </div>
    </div>
  </div>
  <div class="flex flex-row content">
    <p-tieredMenu
      #mainMenu
      [model]="items"
      [ngClass]="{'expanded':menuExpanded,'contracted':!menuExpanded}"
      [popup]="isMobile">
      <ng-template pTemplate="item" let-item let-hasSubmenu="hasSubmenu">
        <a pRipple class="flex align-items-center p-menuitem-link" [routerLink]="item.route">
          <span [class]="item.icon" class="p-menuitem-icon"></span>
          <span *ngIf="menuExpanded">{{ item.label }}</span>
          <i *ngIf="hasSubmenu" class="pi pi-angle-right ml-auto text-primary"></i>
        </a>
      </ng-template>
    </p-tieredMenu>
    <div class="pl-2 pr-2 pb-2 content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="footer">
    UDT Online &copy; All rights reserved
  </div>
</div>
<p-toast key="global-toast"></p-toast>
<p-confirmDialog key="global-confirmation-dialog"></p-confirmDialog>
